import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/homepage-layout';
import { getIcon } from '../util/icon-helper';
import { useSiteImage } from '../hooks/use-site-image';
import { useSiteMetadata } from '../hooks/use-site-metadata';

export const pageQuery = graphql`
  query Tools {
    site {
      siteMetadata {
        tools {
          title
          description
          href
          icon
          iconForeground
          iconBackground
        }
        author {
          name
          href
        }
      }
    }
  }
`;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Page({ data, location }) {
  const tools = data.site.siteMetadata.tools;
  const { siteImage } = useSiteImage();
  const { siteMetadata } = useSiteMetadata();

  return (
    <Layout
      title="Tools"
      description="A carefully curated list of tools for developers, marketers, and others."
      image={siteImage}
      canonicalURL={siteMetadata.siteUrl + location.pathname}
    >
      <div className="px-4 pb-12 mx-auto mt-8 max-w-7xl sm:mt-12 sm:px-6">
        <div className="px-4 pb-10 mx-auto mt-16 max-w-7xl sm:mt-24 sm:px-6">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block">Tools</span>
            </h1>
            <p className="max-w-md mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              A list of tools for developers, marketers, and others.
            </p>
          </div>
        </div>

        <div className="overflow-hidden bg-gray-200 divide-y divide-gray-200 rounded-lg shadow sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
          {tools.map((tool, idx) => {
            const icon = getIcon(tool.icon);

            return (
              <div
                key={tool.title}
                className={classNames(
                  idx === 0
                    ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                    : '',
                  idx === 1 ? 'sm:rounded-tr-lg' : '',
                  idx === tools.length - 2 ? 'sm:rounded-bl-lg' : '',
                  idx === tools.length - 1
                    ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                    : '',
                  'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
                )}
              >
                <div>
                  <span
                    className={classNames(
                      tool.iconBackground,
                      tool.iconForeground,
                      'rounded-lg inline-flex p-3 ring-4 ring-white',
                    )}
                  >
                    <icon.icon
                      className="w-6 h-6"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="mt-8">
                  <h3 className="text-lg font-medium">
                    <a
                      href={tool.href}
                      className="focus:outline-none"
                    >
                      {/* Extend touch target to entire panel */}
                      <span
                        className="absolute inset-0"
                        aria-hidden="true"
                      />
                      {tool.title}
                    </a>
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    {tool.description}
                  </p>
                </div>
                <span
                  className="absolute text-gray-300 pointer-events-none top-6 right-6 group-hover:text-gray-400"
                  aria-hidden="true"
                >
                  <svg
                    className="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
}
