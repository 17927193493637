const {
  AcademicCapIcon,
  ArchiveIcon,
  BadgeCheckIcon,
  CashIcon,
  ClockIcon,
  DatabaseIcon,
  DocumentReportIcon,
  DocumentTextIcon,
  LightningBoltIcon,
  LockClosedIcon,
  PresentationChartBarIcon,
  ReceiptRefundIcon,
  TruckIcon,
  UsersIcon,
} = require('@heroicons/react/outline');

// https://heroicons.com/
function getIcon(name) {
  switch (name) {
    case 'AcademicCapIcon':
      return { icon: AcademicCapIcon };
    case 'ArchiveIcon':
      return { icon: ArchiveIcon };
    case 'BadgeCheckIcon':
      return { icon: BadgeCheckIcon };
    case 'CashIcon':
      return { icon: CashIcon };
    case 'ClockIcon':
      return { icon: ClockIcon };
    case 'DatabaseIcon':
      return { icon: DatabaseIcon };
    case 'DocumentReportIcon':
      return { icon: DocumentReportIcon };
    case 'DocumentTextIcon':
      return { icon: DocumentTextIcon };
    case 'LightningBoltIcon':
      return { icon: LightningBoltIcon };
    case 'LockClosedIcon':
      return { icon: LockClosedIcon };
    case 'PresentationChartBarIcon':
      return { icon: PresentationChartBarIcon };
    case 'ReceiptRefundIcon':
      return { icon: ReceiptRefundIcon };
    case 'TruckIcon':
      return { icon: TruckIcon };
    case 'UsersIcon':
      return { icon: UsersIcon };

    default:
      throw new Error(`Unknown icon: ${name}`);
  }
}

module.exports = {
  getIcon,
};
